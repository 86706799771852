@import url("https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;600;700;800;900&display=swap");

/* CUSTOM PACKAGE STYLES */
@import "react-phone-number-input/style.css";
@import "react-tooltip/dist/react-tooltip.css";
@import "react-datepicker/dist/react-datepicker.css";
@import "react-input-checkbox/lib/react-input-checkbox.min.css";
@import "react-time-picker/dist/TimePicker.css";
@import "react-clock/dist/Clock.css";

/* COMPONENT STYLES */
@import "./components/header.scss";
@import "./components/footer.scss";

/* PAGES STYLES */
@import "./pages/authentication.scss";
@import "./pages/users.scss";
@import "./pages/activity.scss";
@import "./pages/rewards.scss";
@import "./pages/reports.scss";
@import "./pages/campaigns.scss";
@import "./pages/segments.scss";
@import "./pages/dashboard.scss";
@import "./pages/organization-settings.scss";
@import "./pages/coupon-codes.scss";
@import "./pages/agent-detail.scss";
@import "./pages/blogs.scss";
@import "./pages/blog-detail.scss";
@import "./responsive.scss";

body {
    font-family: "Figtree", sans-serif;
    overflow: auto;
    margin: auto;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    max-width: 1400px !important;
}

.container-full-width {
    max-width: 100% !important;
}

.h-100 {
    height: 100% !important;
}

.justify-center {
    justify-content: center !important;
}

.justify-between {
    justify-content: space-between !important;
}

.justify-start {
    justify-content: flex-start !important;
}

.justify-end {
    justify-content: flex-end !important;
}

.align-center {
    align-items: center !important;
}

.align-end {
    align-items: flex-end !important;
}

.text-center {
    text-align: center !important;
}

.primary-btn {
    width: 100%;
    border-radius: 5px;
    background-color: #5f2968;
    border: 2px solid #5f2968;
    font-size: 14px;
    font-weight: 600;
}

.primary-btn:hover {
    color: #5f2968;
    background-color: #fff;
    border: 2px solid #5f2968;
}

.pl-0 {
    padding-left: 0px !important;
}

.mb-0 {
    margin-bottom: 0px !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mb-25 {
    margin-bottom: 25px !important;
}

.p-relative {
    position: relative !important;
}

/* FORM STYLE */
.form-group {
    margin-bottom: 15px;

    .form-label {
        font-size: 14px;
        font-weight: 600;

        span {
            color: red;
        }
    }

    .form-control {
        position: relative;
        width: 100%;
        border: 1px solid #dbdfea;
        font-size: 14px;
        padding: 8px 12px;
    }

    .time-control {
        position: relative;
        width: 100%;
        display: block;
        border: 1px solid #dbdfea;
        font-size: 14px;
        padding: 8px 12px;
        border-radius: 5px;
    }

    .social-control {
        padding-left: 35px;
    }

    .form-control::placeholder {
        font-size: 13px;
    }

    .social-icon {
        position: absolute;
        top: 12px;
        left: 10px;
    }

    .rdw-editor-toolbar {
        border: 1px solid #e5e5e5 !important;
        border-radius: 5px !important;

        .rdw-block-wrapper {
            font-size: 13px !important;

            .rdw-block-dropdown {
                .rdw-dropdown-selectedtext {
                    text-decoration: none !important;
                    color: #323232 !important;
                }
            }
        }
    }

    .texteditor-control {
        width: 100%;
        min-height: 150px;
        border: 1px solid #dbdfea;
        border-radius: 5px;
        padding: 15px;

        .DraftEditor-root {
            .public-DraftEditorPlaceholder-root {
                font-size: 13px !important;
            }

            .public-DraftEditor-editorContainer {
                font-size: 14px !important;
                font-weight: 600 !important;
                color: #323232 !important;
            }

            .public-DraftStyleDefault-block {
                margin-top: 0px !important;
            }
        }
    }

    .checkbox-control {
        margin-right: 30px;

        .form-check-label {
            font-size: 14px;
            font-weight: 500;
        }
    }
}

.datepicker-control {
    width: 100%;
    font-size: 14px;
    border: 1px solid #dbdfea;
    padding: 8px 12px;
    border-radius: 5px;
}

.textarea-control {
    display: block;
    width: 100%;
    font-size: 14px;
    border: 1px solid #dbdfea;
    padding: 8px 12px;
    border-radius: 5px;
}

.datepicker-control:focus {
    outline: none;
}

.phone-control {
    .PhoneInputInput {
        border: 1px solid #dbdfea;
        border-radius: 5px;
        padding: 6px 12px;
    }

    .PhoneInputInput::placeholder {
        font-size: 13px;
    }

    .PhoneInputCountry {
        border: 1px solid #dbdfea;
        border-radius: 5px;
        padding: 5px 10px;
    }
}

.main {
    min-height: calc(100vh - 155px);
    position: relative;
}

.banner-background {
    width: 100%;
    padding-top: 120px;
    padding-bottom: 120px;
    position: absolute;
    top: 0;
    background-color: #323232;
}

.border-btn {
    height: 100%;
    background-color: #fff;
    color: #323232;
    font-size: 14px;
    font-weight: 600;
    border: 1px solid #dbdfea;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 10px;
}

.border-btn:hover {
    background-color: #5f2968;
    color: #fff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
}

.resp-start {
    justify-content: flex-end;
}
